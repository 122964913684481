import React    from 'react';
import {Outlet} from "react-router-dom";

import {Card, CardContent} from "@mui/material";
import imgBgAuthLeft       from "../../../asset/images/instrument2.jpg";
import imgLogo       from "../../../asset/images/WTMU_Header Logo_v2_Black.png";
function Index() {
    debugger;
    return (<div className="container-fluid d-flex justify-content-center align-items-center vh-100 bg-auth">
    <div className="row w-100 justify-content-center">
        <div className="col-md-8">
            <Card className="rounded-5">
                <CardContent className="p-0">
                    <div className="row g-0">
                        <div className="col-md-7 d-none d-md-block">
                            <img src={imgBgAuthLeft} alt="" className="img-fluid1"/>
                        </div>
                        <div className="col-md-5 d-flex align-items-center">
                            <div className="w-100 p-3">
                                <div className="text-center " style={{marginBottom:'50px'}}>
                                    <img className='img-fluid' src={imgLogo}  alt="logo"/>
                                </div>
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    </div>
</div>);
}

export default Index;