import React, {lazy, useState, Suspense, useEffect} from 'react';
import {Outlet, useLocation, useNavigate}           from "react-router-dom";
import {useStateValue}                              from "../../../providers/StateProvider";
import style                                        from '../style.module.scss';
import clsx                                         from "clsx";
import {routerPath}                                 from "../../../config/constents";

const StudentHeaderNav      = lazy(() => import('../includes/studentHeaderNavBar'));
const StudentHeaderMenuBar  = lazy(() => import('../includes/studentHeaderMenuBar'));
const StudentFooter         = lazy(() => import('../includes/studentFooter'));

      

function Index() {
    let location                              = useLocation();
    const navigate                            = useNavigate();
    const [{user}]: any                       = useStateValue();
    const [_toggleSidebar, _setToggleSidebar] = useState<boolean>(true);
    const [_isMobile, _setIsMobile]           = useState<boolean>(true);

    // useEffect(() => {
    //     debugger
    //     const hasWindow = typeof window !== 'undefined';
    //     const width     = hasWindow ? window.innerWidth : null;
        
    //     if (!user) {
    //         navigate(routerPath.auth.home, {state: {from: location}, replace: true});
    //     }

    //     if (width && width <= 768) {
    //         _setIsMobile(true);
    //         _setToggleSidebar(true);
    //     } else {
    //         _setIsMobile(false);
    //         setTimeout(() => _setToggleSidebar(false), 100);
    //     }

    // }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
    <div style={{background:' #0E4A42'}}>
    {user && <Suspense fallback={null}>
            <StudentHeaderNav/>
            <StudentHeaderMenuBar/>
            </Suspense>}
        <main className={clsx({[style.studentContentPage]: user}, style.studentContentPageFull, 'container')}  >
            <div>
                 <div className={clsx(style.scrollBar)}> 
                     <Outlet/>
                 </div> 
            </div>
          
        </main>
        <StudentFooter />
       
      
        </div>
    </>);
}

export default Index;