import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../components/layouts/authLayout";
import { routerPath } from "../config/constents";
import AxiosProvider from "../providers/AxiosProvider";
import LoaderControl from "../components/layouts/helpers/loaderControl";
import AppLayout from "../components/layouts/appLayout";
import { useStateValue } from "../providers/StateProvider";
import StudentLayout from "../components/layouts/studentLayout";
import { Home } from "@mui/icons-material";

const Login = lazy(() => import("../pages/auth/login"));
const Fileupload = lazy(() => import("../pages/fileupload"));
const MemberProfile = lazy(() => import("../pages/memberprofile"));
const MemberCourse = lazy(() => import("../pages/member/cource"));
const MemberDocument = lazy(() => import("../pages/member/documents"));
const AttendanceList = lazy(() => import("../pages/admin/attendance"));
const StudentAttendance = lazy(
  () => import("../pages/admin/studentattendance")
);
const AddCourses = lazy(() => import("../pages/admin/addcourses"));
const Listcourses = lazy(() => import("../pages/admin/listcourses"));
const Grade = lazy(() => import("../pages/admin/grade"));
const Batch = lazy(() => import("../pages/admin/batch"));
const ListBatch = lazy(()=> import ("../pages/admin/listbatch"));
const CourseCertificate = lazy(
  () => import("../pages/admin/coursecertificate")
);
const Addapplicants = lazy(() => import("../pages/admin/addapplicants"));
const ListApplicnats = lazy(() => import("../pages/admin/listapplicants"));
const CourseDetails = lazy(
  () => import("../pages/member/cource/courseDetails")
);
const AddAnnouncement = lazy(() => import("../pages/admin/addannouncement"));
const ListAnnouncement = lazy(() => import("../pages/admin/listannouncement"));
const AddPage = lazy(() => import("../pages/admin/addpages"));
const AddContent = lazy(() => import("../pages/admin/addcontent"));
const ListContent = lazy(() => import("../pages/admin/listcontent"));
const Homeslider = lazy(() => import("../pages/admin/homeslider"));
const ListPage = lazy(() => import("../pages/admin/listpages"));
const Dashboard = lazy(() => import("../pages/admin/dasboard"));
const AddFaculty = lazy(() => import("../pages/admin/addfaculty"));
const ListFaculty = lazy(() => import("../pages/admin/listfaculty"));
const Schedule = lazy(() => import("../pages/admin/schedule"));
const AddSchedule = lazy(() => import("../pages/admin/addschedule"));
const AddApplicantDetail = lazy(()=> import("../pages/admin/addapplicantdetails"));
const ListApplicantDetail = lazy(()=> import("../pages/admin/listapplicantdetails"));
const Promocode = lazy(()=> import ("../pages/admin/promocode"));
const CourseContent = lazy(()=> import ("../pages/admin/coursecontent"));
const ExamSchedule = lazy(()=> import ("../pages/admin/examschedule"));
const ExamResult = lazy(()=> import ("../pages/admin/examresult"));


//studentLogin
const StudentDashboard = lazy(
  () => import("../pages/studentlogin/studentdashboard")
);
const CourseCompleted = lazy(
  () => import("../pages/studentlogin/coursecompleted")
);
const CourseCatalog = lazy(() => import("../pages/studentlogin/coursecatalog"));
const CourseEnroll = lazy(
  () => import("../pages/studentlogin/courseenrollment")
);
const StudentcourseDetails = lazy(
  () => import("../pages/studentlogin/coursedetails")
);
const CourseStatus = lazy(() => import("../pages/studentlogin/coursestatus"));
const CatalogDetails = lazy(
  () => import("../pages/studentlogin/catalogdetails")
);
const StudentProfile = lazy(
  () => import("../pages/studentlogin/studentprofile")
);
const TransactionDetails = lazy(
  () => import("../pages/studentlogin/transactiondetails")
);
const FacultyAssign = lazy(() => import("../pages/admin/facultyassign"));
const StdAttendance = lazy(() => import("../pages/studentlogin/stdattendance"));
const StdSchedule = lazy(() => import("../pages/studentlogin/stdschedule"));

function AppRoutes() {
  const [{ user }]: any = useStateValue();
  return (
    <>
      <AxiosProvider />
      <Routes>
        <Route path={routerPath.auth.home} element={<AuthLayout />}>
          <Route
            path={routerPath.auth.home}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Login />
              </Suspense>
            }
          />
        </Route>
        <Route path={routerPath.auth.home} element={<AuthLayout />}>
          <Route
            path={routerPath.auth.home + "/:type/:id"}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Login />
              </Suspense>
            }
          />
        </Route>
        <Route path={routerPath.home} element={<AppLayout />}>
          {/* <Route
            path={routerPath.memberProfile}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MemberProfile />
              </Suspense>
            }
          /> */}

          {/* <Route
            path={routerPath.membercourse}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MemberCourse />
              </Suspense>
            }
          /> */}

          {/* <Route
            path={routerPath.memberDocument}
            element={
              <Suspense fallback={<LoaderControl />}>
                <MemberDocument />
              </Suspense>
            }
          /> */}
          <Route
            path={routerPath.dashboard}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path={routerPath.studentattendence}
            element={
              <Suspense fallback={<LoaderControl />}>
                <StudentAttendance />
              </Suspense>
            }
          />

          <Route
            path={routerPath.listAttendance}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AttendanceList />
              </Suspense>
            }
          />

          <Route
            path={routerPath.addcourses}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AddCourses />
              </Suspense>
            }
          />

          {/* <Route path={routerPath.addcourses+"/:id"} element={
              <Suspense fallback={<LoaderControl />}>
                <AddCourses />
              </Suspense>
            }
          /> */}

          <Route
            path={routerPath.listcourses}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Listcourses />
              </Suspense>
            }
          />
          <Route
            path={routerPath.coursecertificate}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CourseCertificate />
              </Suspense>
            }
          />
          <Route
            path={routerPath.addapplicants}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Addapplicants />
              </Suspense>
            }
          />
          <Route
            path={routerPath.grade}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Grade />
              </Suspense>
            }
          />
         <Route
            path={routerPath.batch}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Batch />
              </Suspense>
            }
          />
           <Route
            path={routerPath.listbatch}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ListBatch />
              </Suspense>
            }
          />
          <Route
            path={routerPath.listapplicants}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ListApplicnats />
              </Suspense>
            }
          />
          <Route
            path={routerPath.addannouncement}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AddAnnouncement />
              </Suspense>
            }
          />
          <Route
            path={routerPath.listannouncement}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ListAnnouncement />
              </Suspense>
            }
          />
           <Route
            path={routerPath.addapplicantdetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AddApplicantDetail />
              </Suspense>
            }
          />
            <Route
            path={routerPath.listapplicantdetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ListApplicantDetail />
              </Suspense>
            }
          />
            <Route
            path={routerPath.promocode}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Promocode />
              </Suspense>
            }
          />
          <Route
            path={routerPath.addpage}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AddPage />
              </Suspense>
            }
          />
          <Route
            path={routerPath.addcontent}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AddContent />
              </Suspense>
            }
          />
          <Route
            path={routerPath.listpage}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ListPage />
              </Suspense>
            }
          />
          <Route
            path={routerPath.listcontent}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ListContent />
              </Suspense>
            }
          />
          <Route
            path={routerPath.homeslider}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Homeslider />
              </Suspense>
            }
          />
          <Route
            path={routerPath.coursedetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CourseDetails CourseId='0' />
              </Suspense>
            }
          />
          <Route
            path={routerPath.addfaculty}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AddFaculty />
              </Suspense>
            }
          />
          <Route
            path={routerPath.listfaculty}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ListFaculty />
              </Suspense>
            }
          />
          <Route
            path={routerPath.schedule}
            element={
              <Suspense fallback={<LoaderControl />}>
                <Schedule />
              </Suspense>
            }
          />
           <Route
            path={routerPath.examschedule}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ExamSchedule />
              </Suspense>
            }
          />
          <Route
            path={routerPath.examresult}
            element={
              <Suspense fallback={<LoaderControl />}>
                <ExamResult />
              </Suspense>
            }
          />
          <Route
            path={routerPath.addschedule}
            element={
              <Suspense fallback={<LoaderControl />}>
                <AddSchedule />
              </Suspense>
            }
          />
          <Route
            path={routerPath.facultyassign}
            element={
              <Suspense fallback={<LoaderControl />}>
                <FacultyAssign />
              </Suspense>
            }
          />
           <Route
            path={routerPath.coursecontent}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CourseContent />
              </Suspense>
            }
          />
        </Route>
        //studentLogin
        <Route path={routerPath.home} element={<StudentLayout />}>
          <Route
            path={routerPath.courseEnroll}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CourseEnroll />
              </Suspense>
            }
          />
          <Route
            path={routerPath.StudentDashboard}
            element={
              <Suspense fallback={<LoaderControl />}>
                <StudentDashboard />
              </Suspense>
            }
          />
          <Route
            path={routerPath.courseCompleted}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CourseCompleted />
              </Suspense>
            }
          />
          <Route
            path={routerPath.courseCatalog}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CourseCatalog />
              </Suspense>
            }
          />
          <Route
            path={routerPath.studentcourseDetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <StudentcourseDetails />
              </Suspense>
            }
          />
          <Route
            path={routerPath.courseStatus}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CourseStatus />
              </Suspense>
            }
          />
          <Route
            path={routerPath.catalogDetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <CatalogDetails />
              </Suspense>
            }
          />
          <Route
            path={routerPath.studentProfile}
            element={
              <Suspense fallback={<LoaderControl />}>
                <StudentProfile />
              </Suspense>
            }
          />
          <Route
            path={routerPath.transactionDetails}
            element={
              <Suspense fallback={<LoaderControl />}>
                <TransactionDetails />
              </Suspense>
            }
          />
          <Route
            path={routerPath.stdattendance}
            element={
              <Suspense fallback={<LoaderControl />}>
                <StdAttendance />
              </Suspense>
            }
          />
          <Route
            path={routerPath.stdschedule}
            element={
              <Suspense fallback={<LoaderControl />}>
                <StdSchedule />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
export default AppRoutes;
