import React, {lazy, useState, Suspense, useEffect} from 'react';
import {Outlet, useLocation, useNavigate}           from "react-router-dom";
import {useStateValue}                              from "../../../providers/StateProvider";
import style                                        from '../style.module.scss';
import clsx                                         from "clsx";
import {routerPath}                                 from "../../../config/constents";

const SideNavBar   = lazy(() => import('../includes/sideNavBar'));
const HeaderNavBar = lazy(() => import('../includes/headerNavBar'));

function Index() {
    let location                              = useLocation();
    const navigate                            = useNavigate();
    const [{user}]: any                       = useStateValue();
    const [_toggleSidebar, _setToggleSidebar] = useState<boolean>(true);
    const [_isMobile, _setIsMobile]           = useState<boolean>(true);

    useEffect(() => {
        debugger
        const hasWindow = typeof window !== 'undefined';
        const width     = hasWindow ? window.innerWidth : null;
        
        if (!user) {
            navigate(routerPath.auth.home, {state: {from: location}, replace: true});
        }

        if (width && width <= 768) {
            _setIsMobile(true);
            _setToggleSidebar(true);
        } else {
            _setIsMobile(false);
            setTimeout(() => _setToggleSidebar(false), 100);
        }

    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {user && <Suspense fallback={null}>
            <HeaderNavBar toggleSidebarUpdates={() => _setToggleSidebar(!_toggleSidebar)} initialState={_toggleSidebar} isMobile={_isMobile}/>
            <SideNavBar toggleSidebar={_toggleSidebar} isMobile={_isMobile}/>
        </Suspense>}
        <main className={clsx({[style.contentPage]: user}, {[style.contentPageFull]: _toggleSidebar || _isMobile})}>
            <div className="p-3">
                <Outlet/>
            </div>
        </main>
    </>);
}

export default Index;