const base = "/";
//const LocalapiBase           ="https://localhost:7224/api/"
const apiBase           ="https://api.worldtamilmusicuniversity.com/api/"
//const paypalClientId="AayAcUfgnx8h0Ge4_plLAyb2uTQGUH7_wHOK7YfJry5iDn9HRJUXFfDlckxJbkSfq_Gv_j83x0hJxx44" //SandBox
const paypalClientId="AWTpMX60JnCBzqC8A8pLIm82I57sTWXpol4DWaHOT0CMJAULa9qA9mGAY1NuZpBGemfYOqpTuc4I_icx" //Live

//const redirectURL           ="https://uat.worldtamilmusicuniversity.com/registration"
const redirectURL           ="https://worldtamilmusicuniversity.com/registration"

export const routerPath = {
    redirectURL                                 : redirectURL,
    paypalClientId                              : paypalClientId,
    home                                        : base,
    fileUpload                                  : base + "fileupload",
    // memberProfile                               : base + "memberprofile",
   // membercourse                                : base + "membercourse",
    grade                                       : base + "grade",
    batch                                       : base + "batch",
    listbatch                                   : base + "listbatch",
    // memberDocument                              : base + "memberdocument",
    addcourses                                  : base + "addcourses",
    listcourses                                 : base + "listcourses",
    coursecertificate                           : base + "coursecertificate",
    addapplicants                               : base + "addapplicants",
    listapplicants                              : base + "listapplicants",
    listAttendance                              : base + "list-attendance",
    studentattendence                           : base + "studentattendence",
    coursedetails                               : base + "coursedetails",
    addannouncement                             : base + "addannouncement",
    listannouncement                            : base + "listannouncement",
    addpage                                     : base + "addpage",
    listpage                                    : base + "listpage",
    dashboard                                   : base + "dashboard",
    addfaculty                                  : base + "addfaculty",
    listfaculty                                 : base + "listfaculty",
    schedule                                    : base + "schedule",
    addschedule                                 : base + "addschedule",
    facultyassign                               : base + "facultyassign",
    addcontent                                  : base + "addcontent",
    listcontent                                 : base + "listcontent",
    homeslider                                  : base + "homeslider",
    addapplicantdetails                         : base + "addapplicantdetails",
    listapplicantdetails                        : base + "listapplicantdetails",
    promocode                                   : base + "promocode",
    coursecontent                               : base + "coursecontent",
    examschedule                                : base + "examschedule",
    examresult                                 : base + "examresult",

//studentLogin
 
    StudentDashboard                            :base + "studentdashboard",
    courseCompleted                             :base + "coursecompleted",
    courseCatalog                               :base + "coursecatalog",
    courseEnroll                                :base + "courseenroll",
    studentcourseDetails                        :base + "studentcourseDetails",
    courseStatus                                :base + "coursestatus",
    catalogDetails                              :base + "catalogdetails",
    studentProfile                              :base + "studentprofile",
    transactionDetails                          :base + "transactiondetails",
    stdattendance                               :base + "studentattendance",
    stdschedule                                 :base + "studentschedule",


    auth: {
        home                                    : base,
    },

    API:{
        
        fileupload                              : apiBase + 'upload',
        gettittle                               : apiBase + 'memberregistration/gettitlefordropdown',
        getcountry                              : apiBase + 'memberregistration/getcountrynamelistfordropdown',
        getstate                                : apiBase + 'memberregistration/getstatenamelistfordropdown',
        getCountryCode                          : apiBase + 'memberregistration/getcountrydetailsbyid',
        addcourse                               : apiBase + "course/insertcourse",
        getQualification                        : apiBase + "course/getqualifications",
        listcourse                              : apiBase + "course/getcourselist",
        courseimageupload                       : apiBase + "fileupload/memberprofileupload",
        getCourseDetailsByID                    : apiBase + "course/getcoursebyid",
        deleteCourseDetailsByID                 : apiBase + "course/deletecoursebyid",
        getDocumentType                         : apiBase + "memberregistration/getdocumenttype",
        DeleteDoc                               : apiBase + "memberregistration/deleteDocs",
        GetDoc                                  : apiBase + "memberregistration/getdocument",
        getmemberdetails                        : apiBase + "memberregistration/getmemberdetails", 
        deleteCountryWiseFee                    : apiBase + "course/deletecountryfees",
        CourseAssign                            : apiBase + 'course/courseeassigntomember',
        login                                   : apiBase + 'login/loginfunction',
        getcurrency                             : apiBase + 'course/getcurrencybycourseid',
        
        insertGrade                             : apiBase + 'course/insertOrUpdateMasterGrade',
        getGrade                                : apiBase + 'course/GetMasterGradeDetails',
        insertBatch                             : apiBase + 'course/insertOrUpdateCourseBatch',
        getBatch                                : apiBase + 'course/GetCourseBatchListByCourseId',

        //loginApi

        adminLogin                             : apiBase + "master/getAdminLogin",

        //promoCode

        insertpromocode                         : apiBase + 'master/InsertorUpdatePromoCode',
        getallpromocode                         : apiBase + 'master/GetAllPromoCode',
        deletepromocode                         : apiBase + 'master/DeletePromoCode',

        //applicantStatus

        getapplicantdetail                      : apiBase + 'master/GetMemberCourseDetails',
        getapplicantstatus                      : apiBase + 'master/GetAllStatus',
        updatememberstatus                      : apiBase + 'master/UpdateMemberCourse',
        getPromocodeByCode                      : apiBase + 'master/GetPromoCodeDetails',
        courseenrolltomember                    : apiBase + 'course/courseenrolltomember',
        GetAllCourseCount                       : apiBase + 'master/GetAllCourseCount',
        getpromocode                            : apiBase + 'master/GetAllActivePromoCodes',
        
        getAttendance                           : apiBase + 'attendance/Getattendancedetails',
        getAttendanceListByID                   : apiBase + 'attendance/GetattendancedetailsById',
        getBatchsByID                           : apiBase + "attendance/GetBatchforStudentbyCourse",
        getfacultyList                          : apiBase + "course/GetfacultyListforschedule",
        insertAssignment                        : apiBase + "course/InsertorUpdateFacultyAssignment",
        gridfacultyAssignment                   : apiBase + "course/GridbindforFacultyAssignment",
        getBatchDetailsbyID                     : apiBase + "course/getcoursebyBatchid",

        insertAttendance                        : apiBase + 'attendance/InsertAttendance',
        updateAttendance                        : apiBase + 'attendance/UpdateAttendance',
        getAttendanceById                       : apiBase + 'attendance/Editattendancedetails',
        getMemberList                           : apiBase + 'attendance/GetMemberlist',
        getstudcourseattendance                 : apiBase + 'attendance/GetCourseforStudent',
        getstudbatchattendance                  : apiBase + 'attendance/GetBatchforStudentbyCourse',
        getstudentattendancelist                : apiBase + 'attendance/GetMemberListforAttendacebyId',
        getSchedules                            : apiBase + 'course/Getschedule',
        insertschedule                          : apiBase + 'course/InsertScheduleCourse',
        listschedule                            : apiBase + 'course/GridBindforCourseschedule',
        getgridbind                             : apiBase + 'course/GridBindViewDetails',
        insertannouncemnt                       : apiBase + 'memberregistration/saveannouncments',
        getannouncements                        : apiBase + 'memberregistration/getannouncement',
        getDashboardDetails                     : apiBase + 'dashboard/GetNumberOfUsersStatus',
        insertorupdatepages                     : apiBase + 'contentManagement/insertPages',
        getpages                                : apiBase + 'contentManagement/getpages',
        listlanguages                           : apiBase + 'contentManagement/getLanguageList',
        insertorupdatecontent                   : apiBase + 'contentManagement/insertPageContent',
        getcontentList                          : apiBase + 'contentManagement/getPageContentByPageID',
        getSliders                              : apiBase + 'contentManagement/getSliders',
        postSliders                             : apiBase + 'contentManagement/insertSliders',
 

        ProfileUpdate:{
            getMemberDetail                     : apiBase + 'memberregistration/editprofile',
            getDegreeList                       : apiBase + 'memberregistration/getDegreeList',
            updateRgistration                   : apiBase + 'memberregistration/updateregistrationdetails',
            insertMemberDoc                     : apiBase + 'memberregistration/uploadMemberDocs',
            deleteMemberDoc                     : apiBase + 'memberregistration/deleteDocs',
            deleteIdentity                      : apiBase + 'memberregistration/DeleteIdentity',

       },

       ApplicantDetails:{
        insertorupdateApplicant                 : apiBase + 'memberregistration/insertorupdateapplicants',
        deleteapplicantDetailsByID              : apiBase + 'memberregistration/deleteapplicantsbyid',
        editapplicantdetailsByID                : apiBase + 'memberregistration/getmemberbyid'
       },
       
       FacultyAPI:{
         facultyupdate                           : apiBase + 'faculty/updateFaculty',
       },
       
       StudentAPI : {
        StudentDashboard                        : apiBase + 'dashboard/Getdashboarddetails',
        courseEnroll                            : apiBase + 'course/getMemberCourseByStatus',    
        courseCompleted                         : apiBase + 'course/getMemberCourseByStatus',    
        courseCatalogdet                        : apiBase + 'course/getMemberCourseByStatus',
        TransactionDetails                      : apiBase + 'course/getTransactionDetail',    
        DownloadInvoice                         : apiBase + 'course/downloadinvoice',
        Getscheduleforstudent                   : apiBase + 'course/GetscheduleforStudent',
        DeleteCertificate                       : apiBase + 'memberregistration/DeleteCertificate'
       },

       //Box Integration Api
       BoxAPI : {
        createFolder                           : apiBase + 'box/createFolder1',
        downloadFile                           : apiBase + 'box/downloadFile',
        createFile                             : apiBase + 'box/createLargeFile1',
        getAllBoxFile                          : apiBase + 'box/getAllFilesUsingFolderId',
        deleteBoxFile                          : apiBase + 'box/deleteFile',
        updateFile                             : apiBase + 'box/UpdateFileDetails',
       }   
    }
}

export const SwalMessages = {
    InsertSuccess                   :"Insert Success",
    UpdateSuccess                   :"Update Success",
    CourseInsertSuccess             :"Course Enrollment Successful",
    CourseAppliedSuccess            :"Course Application Successful",
    CourseUpdateSuccess             :"Course Successfully Modified",
    AttendanceInsert                :"Attendance Successfully Inserted",
    AttendanceUpdate                :"Attendance Successfully Modified",
    SomethingWentWrong              :"Something went wrong",
    Countryaldreadyselected         :"Country Already Selected",
    Fileupload                      :"File Successfully Uploaded",
    Passwordnotmatch                :"Password Not Match",
    ProfileInsertSuccess            :"Profile Successfully Added",
    ProfileUpdateSuccess            :"Profile Successfully Updated",
    ApplicantInsertSuccess          :"Applicant Successfully Added",
    ApplicantUpdateSuccess          :"Applicant Successfully Updated",
    FacultyInsertSucess             :"Faculty Successfully Inserted ",
    FacultyUpdateSucess             :"Faculty Successfully Updated ",
    FacultyDeleteSucess             :"Faculty Successfully Deleted ",
    ApplicantDeleteSuccess          :"Applicant Successfully Deleted",
    DataNotFound                    :"Data Not Found",
    CourseDeletedSuccess            :"Course Successfully Deleted",
    CheckBoxSelect                  :"Please select anyone Eligiblity/Tamil Proficiency",
    CheckBoxStudentSelect           :"Please select anyone Checkbox",
    SelectDocumentType              :"Please Select Document Type",
    DocumentDeleteSuccess           :"Document Successfully Deleted",
    DocumentInsertSuccess           :"Document Successfully Uploaded",
    CourseAssignedSuccess           :"Course Assigned Successfully",
    ScheduleAddedSuccess            :"Schedule Added Successfully"
}
