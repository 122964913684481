import { authService } from './authService';

type ActionType = "SET_USER" | "detdata";

export interface PropReducerAction {
    user?: any;
    type?: ActionType;
    data?: any;
  }

  export const initialState = {
    user: authService.user(),
    detdata: {}
  }
console.log(initialState)

  const stateService = (state: any, action: PropReducerAction) => {
    //console.log("action", action);
    //console.log("state", state);
    switch (action.type) {
      case "SET_USER":
        return {
          ...state,
          user: action.user,
        };
      case "detdata":
        return {
          ...state,
          detdata: { ...action.data },
        };
        default:
      return state;
    }
}  
export default stateService;