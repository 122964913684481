import { base64 } from './helperService';

const authService = {
    setUser() {
        const _tempData = {};
        return _tempData;
    },
    removeUser() {
        localStorage.removeItem('_viveka_temp');
        return null;
    },
    user() {
        const _tempData: string | null = localStorage.getItem('_viveka_temp');

        if (!_tempData) return null;

        try {
            const user = JSON.parse(_tempData);
            console.log(user)
            const response = {
                id: user?.id,
                firstName: user?.firstName,
                lastName: user?.lastName,
                email: user?.email,
                token: user?.token,
                menuNames: user?.menuNames,
                type: user?.type
            }
            return response;
        } catch (error) {
            localStorage.clear();
            return null;
        }

    }
}

export { authService };